<template>
  <v-container>
    <v-btn text @click="$router.go(-1)" ><v-icon
        left
        dark
      >
        mdi-backburger
    </v-icon> Zurück zu Referenzen</v-btn>
    <ImageGallery v-model="galleryData" />
  </v-container>
</template>
<script>
import ImageGallery from '@/components/ImageGallery.vue';

export default {
  name: 'eintracht-fassade',
  components: {
    ImageGallery,
  },
  data: () => ({
    galleryData: {
      imagePath:
        'https://medo-sanierung.de/public_files/img/references/eintracht-fassade',
      imageCount: 20,
      imageFileFormat: '.jpg',
      name: 'Sanierung der Fassade in der Turnabteilung',
      description:
        'Alle Risse am Mauerwerk des alten Eintrachter Turngebäudes wurden mit speziellem Spiralankersystem sorgfältich saniert, einige Kabel und Leitungen verschwanden unter dem Putz. Im Zuge der Sanierung wurde ein alter Kellerabgang in einen ordnungsgemäßen und gebrauchsfähigen Zustand versetzt, sowie eine hystorische Backsteinsäule sandgestrahlt, behandelt und versiegelt. Die komplette Fassadenfläche wurde mit Panzergewebe und speziellem Kleber armiert. Nach dem Oberputz kam der neue Anstrich mit den typischen "Eintracht-Farben", der das Gebäude in einem neuen Licht erscheinen ließ.',
    },
    //
  }),
};
</script>
